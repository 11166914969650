import type { AvailableCohortsResponse } from "./types/cohort";

export function getProgramCohorts(ids: string) {
  const BASE = process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string;

  const PARTNER_ID = process.env[
    "NEXT_PUBLIC_HALL_OF_RECORDS_PARTNER_ID"
  ] as string;

  const url = BASE + `/app-ac/${PARTNER_ID}/search?catalogueIds=${ids}`;
  return fetch(url);
}

export async function getAllCohorts() {
  const BASE = process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string;

  const PARTNER_ID = process.env[
    "NEXT_PUBLIC_HALL_OF_RECORDS_PARTNER_ID"
  ] as string;

  const response = await fetch(`${BASE}/app-ac/${PARTNER_ID}`);
  return response.json() as Promise<AvailableCohortsResponse>;
}
