import { CalendarBlank } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";

import { analyticsEvent } from "@up/analytics";
import { useCart } from "@up/checkout";
import { getProgramCohorts, useTheme } from "@up/data";
import { Spinner } from "@up/ui";

export const ChangeDate = function (props: {
  cancel: () => void;
  currentCohortId: string;
  catalogueItemId: string;
}) {
  const cart = useCart();
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingDates, setIsLoadingDates] = useState(false);
  const [didError, setDidError] = useState(false);
  const [potentialCohorts, setPotentialCohorts] = useState<
    Array<{
      _id: string;
      name: string;
    }>
  >([]);

  const heading = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (heading.current) {
      heading.current.focus();
    }
  }, []);

  useEffect(() => {
    const go = async function () {
      setIsLoadingDates(true);
      try {
        const response = await getProgramCohorts(props.catalogueItemId);

        if (response.ok) {
          const json = await response.json();
          setPotentialCohorts(
            json[0].cohorts.filter((cohort: { _id: string; name: string }) => {
              if (cohort._id === props.currentCohortId) {
                return false;
              }

              const match = cart.lineItems?.find(
                (lineItem: { _id: string }) => {
                  return lineItem._id === cohort._id;
                },
              );

              if (match) {
                return false;
              }

              return true;
            }),
          );
        }

        setIsLoadingDates(false);
      } catch (err) {
        setIsLoadingDates(false);
        setDidError(true);
      }
    };

    go();
  }, [cart.lineItems, props.currentCohortId, props.catalogueItemId]);

  return (
    <div
      className="animation-slide-and-fade-in relative col-span-3 mt-2 flex flex-grow flex-col items-center pt-5 pb-1 text-center"
      data-testid="checkout-list-item-delete-confirmation"
    >
      <CalendarBlank size={56} color="#00d" />
      <div className="mb-2 pt-1 text-2xl" ref={heading} tabIndex={0}>
        Change Dates
      </div>

      <form
        className="max-w-full"
        onSubmit={async (e) => {
          e.preventDefault();
          setIsSaving(true);
          const data = new FormData(e.target as HTMLFormElement);
          const newID = data.get("newCohortId") as string | null;

          if (!newID) {
            return;
          }

          analyticsEvent({
            event: "cartChangeDates",
            location: "cart-drawer",
          });
          await cart.addItem(newID);
          await cart.removeItem(props.currentCohortId);
          setIsSaving(false);
        }}
      >
        {isSaving ? (
          <div className="flex items-center justify-center gap-2 w-full">
            Saving changes...
          </div>
        ) : (
          <div>
            <div className="relative mb-8 w-full">
              {isLoadingDates ? (
                <div className="absolute top-0 left-0 flex items-center justify-center gap-2 left-1/2 -translate-x-1/2 w-full">
                  <Spinner />
                  Loading cohorts
                </div>
              ) : null}

              <select
                name="newCohortId"
                className={`bg-white p-1 rounded border border-blue-100 text-ellipsis max-w-full text-sm ${
                  isLoadingDates ? "opacity-0" : ""
                } ${
                  !isLoadingDates && potentialCohorts.length === 0
                    ? "hidden"
                    : ""
                }`}
                aria-hidden={isLoadingDates || potentialCohorts.length === 0}
                disabled={isLoadingDates || potentialCohorts.length === 0}
              >
                {potentialCohorts.map((c) => {
                  return (
                    <option value={c._id} key={c._id}>
                      {c.name}
                    </option>
                  );
                })}
              </select>

              {!isLoadingDates && potentialCohorts.length === 0 ? (
                <div className="text-base">
                  No additional cohorts are available
                </div>
              ) : null}
            </div>
            <div className="flex items-center justify-center">
              <button
                className={`mr-2 rounded border border-solid border-blue-700 bg-blue-600 px-4 py-1 text-white text-base ${
                  potentialCohorts.length ? "" : "cursor-not-allowed opacity-60"
                }`}
                data-testid="checkout-list-item-change-dates-yes"
                data-location="cart-drawer"
                type="submit"
              >
                Change dates
              </button>
              <button
                type="button"
                className={`rounded border border-solid border-white bg-white px-4 py-1 text-gray-700 text-base`}
                data-testid="checkout-list-item-change-dates-no"
                data-location="cart-drawer"
                onClick={props.cancel}
              >
                cancel
              </button>
            </div>
          </div>
        )}
        {didError ? (
          <div className="pt-2">
            Sorry, an error has occurred. Please try again. If you keep seeing
            this error,{" "}
            <a className="underline" href={`mailto:${theme.supportEmail}`}>
              email support
            </a>
          </div>
        ) : null}
      </form>
    </div>
  );
};
