"use client";

import { Upsell, useCart } from "@up/checkout";
import {
  ApplicationStatus,
  applicationIsDenied,
  applicationIsIncomplete,
  applicationIsPendingReview,
  useOwner,
  useOwnerProfile,
  useProfile,
  useTheme,
} from "@up/data";
import { ApplicationWarning, UPLink } from "@up/ui";

import { LineItem } from "./LineItem";
import LoadingError from "./LoadingError";
import { CartWrapper } from "./Wrapper";
import { isCartOpen } from "../is-cart-open-store";

import type { Profile, ProfileContextType } from "@up/data";

const shouldShowEmptyCart = ({
  profile,
  ownerProfile,
}: {
  profile?: ProfileContextType;
  ownerProfile?: Profile;
}) => {
  return (
    profile?.data?.type === "Guardian" &&
    (applicationIsIncomplete(ownerProfile?.applicationStatus) ||
      applicationIsDenied(ownerProfile?.applicationStatus) ||
      applicationIsPendingReview(ownerProfile?.applicationStatus))
  );
};

const CartEmpty = () => {
  return (
    <div className="border border-dashed border-gray-400 text-center py-12 text-gray-700">
      Cart is empty
    </div>
  );
};

export const Cart = function () {
  const theme = useTheme();
  const cart = useCart();
  const owner = useOwner();
  const profile = useProfile();
  const ownerProfile = useOwnerProfile();

  const shoppingFor = (() => {
    const isGuardian = profile?.data?.type === "Guardian";
    const isProxyShopping = owner?.email !== profile?.data?.email;
    if (isGuardian && isProxyShopping) {
      if (owner?.firstName && owner.lastName) {
        return `${owner.firstName} ${owner.lastName}`;
      } else {
        return owner?.email;
      }
    }
    return "";
  })();

  const showApplicationWarning =
    ownerProfile?.applicationStatus !== ApplicationStatus.Approved &&
    ownerProfile?.applicationStatus !== ApplicationStatus.AdminCompleted;

  return (
    <CartWrapper>
      {cart.didError ? (
        <div className="px-4 pt-3">
          <LoadingError supportEmail={theme.supportEmail} />
        </div>
      ) : (
        <div>
          {shoppingFor ? (
            <div className="px-6">Shopping for {shoppingFor}</div>
          ) : null}
          <br />
          {showApplicationWarning ? (
            <>
              <div className="px-6">
                <ApplicationWarning
                  appStatus={ownerProfile?.applicationStatus}
                  isStudent={profile?.data?.type === "Student"}
                />
              </div>
              <br />
            </>
          ) : null}

          <div className="flex flex-col gap-6 px-6 pt-px">
            {cart.lineItems?.length ? (
              <ul className="flex flex-col gap-2">
                {cart.lineItems.map((item) => {
                  return (
                    <LineItem
                      key={item._id}
                      item={item}
                      removeFn={async function (_id) {
                        return await cart.removeItem(_id);
                      }}
                    />
                  );
                })}
                <Upsell
                  analytics={{
                    location: "cart-drawer",
                  }}
                />
              </ul>
            ) : shouldShowEmptyCart({ profile, ownerProfile }) ? null : (
              <CartEmpty />
            )}

            {cart.lineItems?.length ? (
              <UPLink
                className={theme.buttonPrimaryClass}
                onClick={() => {
                  isCartOpen.set(false);
                }}
                href="/checkout"
              >
                Complete Enrollment
              </UPLink>
            ) : null}
          </div>
        </div>
      )}
    </CartWrapper>
  );
};
