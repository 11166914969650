import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { X } from "@phosphor-icons/react";

import { analyticsEvent } from "@up/analytics";
import { useCart } from "@up/checkout";
import { getAllCohorts } from "@up/data";

type _Program = {
  _id: string;
  name: string;
  cohorts: _Cohort[];
};

type _Cohort = {
  _id: string;
  name: string;
  price: string;
};

const contentAnimationProps = {
  initial: { opacity: 0, transform: "translateY(10px)", height: 0 },
  animate: {
    opacity: 1,
    transform: "translateY(0)",
    height: "auto",
    transition: {
      duration: 0.2,
      ease: [0.17, 0.55, 0.55, 1],
    },
  },
  exit: { opacity: 0, transform: "translateY(10px)", height: 0 },
};

export const Upsell = function Upsell({
  analytics,
}: {
  analytics: {
    location: string;
  };
}) {
  const cart = useCart();

  const [show, setShow] = useState(true);
  const [selectedProgram, setSelectedProgram] = useState<_Program | undefined>(
    undefined,
  );
  const [selectedCohort, setSelectedCohort] = useState<_Cohort | undefined>(
    undefined,
  );
  const [data, setData] = useState<null | _Program[]>(null);

  useEffect(() => {
    const go = async function () {
      const d = await getAllCohorts();
      const data = d.programs
        .map((p) => {
          if (!p.cohorts.length) {
            return undefined;
          }
          return {
            _id: p._id,
            name: p.name,
            cohorts: p.cohorts.map((c) => {
              return {
                _id: c._id,
                name: c.name,
                price: c.formattedRetailPrice,
              };
            }),
          };
        })
        .filter((p) => Boolean(p))
        .filter((p) => {
          if (!p) {
            return false;
          }
          if (!cart?.lineItems?.length) {
            return true;
          }
          const isInCart = cart.lineItems
            .map((item) => {
              return item.catalogueItem._id;
            })
            .includes(p._id);
          return !isInCart;
        });
      setData(data as _Program[]);

      const defaultP = data?.length ? data[0] : undefined;
      const defaultC = defaultP?.cohorts.length
        ? defaultP.cohorts[0]
        : undefined;
      setSelectedProgram(defaultP);
      setSelectedCohort(defaultC);

      return data;
    };

    go();
  }, [cart.lineItems]);

  const addToCart = async (id: string) => {
    analyticsEvent({
      event: "addToCart",
      location: analytics.location,
    });
    await cart.addItem(id);
  };

  if (!cart || !data?.length) {
    return null;
  }

  return (
    <li className="@container">
      <div className="overflow-hidden border border-solid border-yellow-300 px-4 pt-4 mt-8 bg-yellow-50 @md:px-6">
        <div className="flex flex-col mb-4 font-bold justify-between @md:flex-row">
          Interested in another course?
          <button
            className="gap-1 flex items-center text-sm font-normal"
            onClick={() => {
              setShow(!show);
            }}
          >
            <X
              size="16"
              style={{
                transform: show ? "rotate(0)" : "rotate(45deg)",
                transition: "transform 0.2s",
              }}
            />{" "}
            {show ? "no thanks" : "show me more"}
          </button>
        </div>
        <AnimatePresence>
          {show ? (
            <motion.div {...contentAnimationProps}>
              <select
                className="w-full mb-2 bg-white border border-gray-400 rounded py-1 px-2 shadow-sm"
                name="program"
                onChange={(e) => {
                  const p = data.find((p) => {
                    return p._id === e.target.value;
                  });
                  setSelectedProgram(p);
                  setSelectedCohort(p?.cohorts[0]);
                }}
              >
                {data?.map((p) => {
                  return (
                    <option key={p._id} value={p._id}>
                      {p.name}
                    </option>
                  );
                })}
              </select>
              {selectedProgram ? (
                <select
                  className="w-full mb-2 bg-white border border-gray-400 rounded py-1 px-2 shadow-sm"
                  name="cohort"
                  onChange={(e) => {
                    setSelectedCohort(
                      selectedProgram.cohorts.find((c) => {
                        return c._id === e.target.value;
                      }),
                    );
                  }}
                >
                  {selectedProgram?.cohorts.map((c) => {
                    return (
                      <option key={c._id} value={c._id}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
              ) : null}
              <div className="flex justify-between items-center mt-4 @md:justify-start">
                {selectedCohort ? (
                  <>
                    <div className="pb-4 font-mono text-sm opacity-70">
                      {selectedCohort.price}
                    </div>
                    <button
                      className="relative underline leading-none pb-4 px-4 -right-4 text-sm font-bold"
                      data-testid="add-to-cart-button"
                      data-location={analytics.location}
                      disabled={cart.isAddingItem}
                      onClick={() => {
                        addToCart(selectedCohort._id);
                      }}
                    >
                      Add to Cart
                    </button>
                  </>
                ) : null}
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </li>
  );
};
